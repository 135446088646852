import React from 'react'

function TextBeautify() {
    return (
        <div className='blog-text'>
            <p style={{textAlign:'right', color: 'orange', textShadow:'1px 1px 1px black'}}>Last Updated: 10th April, 2023</p>
            <p>
                Sometime your text color do not look prominent because of background color, or you want to insert
                some effects in the text to make it promiment and beautiful.</p>
            <p>
                If you do not want to change text color and background, you can add text shadow to make it
                prominent and beautify as given in following example:</p>
            <div>
                <p>
                    <em>Text Without Shadow</em><br />
                    <em className='blog-text-shadow'>Text With Shadow</em></p>
            </div>
            <p>
                The 'color' property of second text is white. If we keep the same 'color' property of the first text,
                it wouldn't be visible on the light background, difference is text-shadow. Following line of css code is used:<br />
                <em>text-shadow: 1px 1px 2px black;</em></p>
            <p>
                This text-shadow property can be read as:<br />
                1px along x-axis<br />
                1px along y-axix<br />
                2px blur radius<br />
                black color<br />
            </p>
            <p>
                You can add comma separated multiple shadows to the text as given below:<br />
                <em>text-shadow: 1px 1px 2px red, 1px 2px 3px green;</em></p>
        </div>
    )
}

export default TextBeautify