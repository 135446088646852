import React from 'react'
import { Link } from 'react-router-dom';
import fb from '../gallery/social/fb.png';
import youtube from '../gallery/social/youtube.png';

function Footer() {
    return (
        <div className='container footer'>
            <div className='row'>
                <div className='col-3 btn-bottom'>
                    <Link to='/'><button type="button" className='btn-top'>Home</button></Link><br />
                    <Link to='portfolio'><button type="button" className='btn-top'>Portfolio</button></Link><br />
                    <Link to='blog'><button type="button" className='btn-top'>Blog</button></Link>
                </div>
                <div className='col-6 social'>
                    <div className='text-end'><a href="https://www.facebook.com/bitwork.tech">
                        <img src={fb} className="img-fluid" alt="facebook" /></a>
                    </div>
                    <div><a href="https://www.youtube.com/watch?v=UFbcaxHvPls">
                        <img src={youtube} className="img-fluid" alt="youtube" /></a>
                    </div>
                </div>
                <div className='col-3 text-end btn-bottom'>
                    {/* <button type="button">Login</button><br/>
                <button type="button">Register</button><br/> */}
                    <Link to='demo'><button type="button" className='btn-top'>Demo</button></Link>
                </div>
                <div className='col-12 text-center copyright-text'>
                    All rights reserved | Copyright 2023 - 2024
                </div>
            </div>
        </div>
    )
}

export default Footer