import React, { useEffect, useState } from 'react';
import "@fontsource/roboto";
import { Link } from 'react-router-dom';
import myPic from '../gallery/personal/Adeel.jpg';
import { useLocation } from 'react-router-dom';
import DualLinechart from './visuals/DualLinechart';
import { colorPalette as clr, lblExample } from '../SharedCode';

function Header() {
    const isActive = useLocation().pathname.slice(1).split('/')[0];
    const tabRoutes = ['', 'portfolio', 'blog', 'demo'];
    const [currentTab, setCurrentTab] = useState(null);
    let tabRouteIndex = null;
    for (let i = 0; i < tabRoutes.length; i++) {
        if (isActive == tabRoutes[i]) {
            tabRouteIndex = i
            break;
        }
    }
    currentTab != tabRouteIndex && setCurrentTab(tabRouteIndex);

    const tabChange = () => {
        const tabElement = document.getElementsByClassName('nav-tab');
        const tabLink = document.getElementsByClassName('tab-nav-link');
        for (let i = 0; i < tabElement.length; i++) {
            if (i == tabRouteIndex) {
                tabElement[i].classList.contains("nav-inactive") && tabElement[i].classList.remove("nav-inactive");
                tabLink[i].classList.contains("tab-nav-link") && tabElement[i].classList.remove("tab-nav-link");
                !tabElement[i].classList.contains("nav-active") && tabElement[i].classList.add("nav-active");
            }
            else {
                tabElement[i].classList.contains("nav-active") && tabElement[i].classList.remove("nav-active");
                !tabElement[i].classList.contains("nav-inactive") && tabElement[i].classList.add("nav-inactive");
                !tabLink[i].classList.contains("tab-nav-link") && tabElement[i].classList.add("tab-nav-link");
            }
        }
    }
    useEffect(() => {
        tabChange();
    }, [currentTab]);

    ////////////////////////////////////////////
    const paletteProgress = {
        lblX: clr.darkbrown,
        titleX: clr.redish,
        lblY: clr.darkbrown,
        titleY: clr.redish,
        chartTitle: clr.redish,
        legendtxt: clr.darkbrown,
        grid: clr.lightbrown,
    }

    const datasetProgress = [
        {
            label: 'Target',
            data: [0, 5000, 7000, 9000, 11000, 13000, 15000],
            borderColor: clr.redish,
            backgroundColor: clr.redish,
        },
        {
            label: 'Sale',
            data: [0, 3000, 4000, 5000, 6000, 8000, 10000],
            borderColor: clr.carolina,
            backgroundColor: clr.carolina,
        },
    ]
    ////////////////////////////////////////////
    return (
        <div className='container bg-header'>
            <div className='row header'>
                <div className='col-6 col-lg-4'>
                    <div><img src={myPic} className="img-fluid pic-adeel" alt="Adeel" /></div>
                </div>
                <div className='col-lg-4 d-none d-lg-block'>
                    <div className='header-chart' style={{ marginTop: '-55px' }}>
                        <DualLinechart lblData={lblExample} datasets={datasetProgress} palette={paletteProgress} xAxis='' yAxis='EXAMPLE' title='' />
                    </div>
                    {/* <div className='text-center'><img src={imgHeader} className="img-fluid" style={{ height: '130px' }} alt="Line Chart" /></div> */}
                </div>
                <div className='col-6 col-lg-4 text-end rale'>
                    <Link to="demo"><button type="button" className="btn-top" /*onClick={() => setCurrentTab(3)}*/>Demo</button></Link>
                    {/* <button type="button" className="btn-top">Register</button>
                    <button type="button" className="btn-top">Login</button> */}
                </div>
            </div>
            <div className='row justify-content-end text-center'>
                <div className='col-4 nav-tab nav-active'>
                    <Link to="/" className='tab-nav-link' onClick={() => setCurrentTab(0)}>Home</Link>
                </div>
                <div className='col-4 nav-tab nav-inactive'>
                    <Link to="portfolio" className='tab-nav-link' onClick={() => setCurrentTab(1)}>Portfolio</Link>
                </div>
                <div className='col-4 nav-tab nav-inactive'>
                    <Link to="blog" className='tab-nav-link' onClick={() => setCurrentTab(2)}>Blog</Link>
                </div>
            </div>
            <div className='row stripe'>
                <div className='col-12 bg-darkbrown'>
                    Adeel Rauf - Full Stack Developer
                </div>
                <div className='col-12 bg-brown'>
                    whatsapp: +92 3456789 765
                </div>
                <div className='col-12 bg-lightbrown'>
                    email: adeelrauf@gmail.com
                </div>
            </div>
        </div>
    )
}

export default Header