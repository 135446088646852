export const blogs = [
    {
        id: 1,
        topic: 'Who should design your ui/ux?',
        route: 'who-should-design-ui-ux',
    },
    {
        id: 2,
        topic: 'How to middle align vertically?',
        route: 'how-to-middle-align-text-vertically',
    },
    {
        id: 3,
        topic: 'Multi-thread Programming',
        route: 'what-is-multi-thread-programming',
    },
    {
        id: 4,
        topic: 'How to beautify text?',
        route: 'how-to-beautity-text',
    },
    {
        id: 5,
        topic: 'Steps to develop a mobile app',
        route: 'steps-to-develop-mobile-app',
    },
]