import React, { useEffect, useRef, useState } from 'react';
import Projects from './Projects';
import TechStack from './TechStack';
import { videos } from '../data/VideosInfo';
import { colorPalette } from '../SharedCode';

function Portfolio() {
    const [pid, setPid] = useState(24);
    const [tid, setTid] = useState(15);
    const [currentObject, setCurrentObject] = useState(null);
    const [preserveProj, setPreserveProj] = useState([]);
    const [currentTech, setCurrentTech] = useState(null);
    const [preserveTech, setPreserveTech] = useState([]);
/*     const renderProj = useRef(false);
    const renderTech = useRef(false); */

    const loadObject = async () => {
        let srNo = 0;
        let projJsx = [];
        let idName = [];
        const { projects } = await import('../data/ProjectsInfo');
        setCurrentObject(projects.find(obj => obj.id == pid));
        //renderProj.current = true;

        for (let i = projects.length; i > 0; i--) {
            idName.push({
                id: projects[i - 1].id,
                name: projects[i - 1].name,
            })
        }
        //idName.reverse() //Bottom first shall appear when this line of code is commented out

        for (const proj of idName) {
            projJsx.push(
                <div key={'p-srno' + srNo}>
                    <div className='project-sr'>{srNo + 1}</div>
                    <div className='project' onClick={() => { setPid(proj.id); bgStyle(srNo, 'project'); }} style={{ backgroundColor: pid == proj.id && colorPalette.darkbrown, color: pid == proj.id && colorPalette.cream }}>{idName[srNo].name}</div>
                </div>
            );
            srNo++;
        }
        setPreserveProj(projJsx);
    };
    const loadTech = async () => {
        let srNo = 0;
        let techJsx = [];
        let idName = [];
        const { techInfo } = await import('../data/TechInfo');
        setCurrentTech(techInfo.find(tech => tech.id == tid));
        //renderTech.current = true;

        for (let i = techInfo.length; i > 0; i--) {
            idName.push({
                id: techInfo[i - 1].id,
                name: techInfo[i - 1].name,
            })
        }
        //idName.reverse() //Bottom first shall appear when this line of code is commented out

        for (const tech of idName) {
            techJsx.push(
                <div key={'t-srno' + srNo}>
                    <div className='tech-sr'>{srNo + 1}</div>
                    <div className='tech' onClick={() => { setTid(tech.id); bgStyle(srNo, 'tech'); }} style={{ backgroundColor: tid == tech.id && colorPalette.darkbrown, color: tid == tech.id && colorPalette.cream }}>{idName[srNo].name}</div>
                </div>
            );
            srNo++;
        }
        setPreserveTech(techJsx);
    };

    const bgStyle = (index, block) => {
        let elementList;
        if (block == 'project') {
            elementList = document.getElementsByClassName('project');
        }
        else if (block == 'tech') {
            elementList = document.getElementsByClassName('tech');
        }
        for (let i = 0; i < elementList.length; i++) {
            if (index == i) {
                elementList[i].style.backgroundColor = colorPalette.darkbrown;
                elementList[i].style.color = colorPalette.cream;
            }
            else {
                elementList[i].style.removeProperty('background-color');
                elementList[i].style.color = colorPalette.darkbrown;
            }
        }
    }

    const getElement = () => {
        const divImg = document.getElementById('get-height');
        const divSelection = document.getElementsByClassName('set-height');
        for (let i = 0; i < divSelection.length; i++) {
            divSelection[i].style.height = divImg.offsetHeight + "px";
        }
    }
    currentObject && currentTech && getElement();
    
    useEffect(() => {
        document.title = "portfolio - Full Stack + UX/UI Developer";
      }, []);

    useEffect(() => {
        loadObject();
        return () => {
        }
    }, [pid]);
    useEffect(() => {
        loadTech();
        return () => {
        }
    }, [tid]);
    useEffect(() => {
        currentObject && currentTech && getElement();
        return () => {
        }
    }, [currentTech, currentObject]);

    return (currentObject && currentTech &&
        <div className='container'>
            <div className='row' style={{marginTop: '30px'}}>
                <div className='col-12 col-lg-4 col-portfolio set-height'>
                    <div className="stat-heading mb-1">Recent Projects</div>
                    {preserveProj}
                </div>
                <div className='col-12 col-lg-8 col-portfolio' id='get-height'>
                    <Projects id={pid} project={currentObject} />
                </div>
            </div>
            <div className='row' style={{marginTop: '30px'}}>
                <div className='col-12 col-lg-4 col-portfolio set-height'>
                    <div className="stat-heading mb-1">Tech Stack</div>
                    {preserveTech}
                </div>
                <div className='col-12 col-lg-8 col-portfolio set-height'>
                    <TechStack id={tid} tech={currentTech} />
                </div>
            </div>{/* {renderProj.current = false}{renderTech.current = false} */}
            <div className='row' style={{marginTop: '30px'}}>
                <div className='col-12 col-portfolio-video'>
                    <div className="stat-heading mb-1">Video Explanation To Clients</div>
                    <div className='row'>
                        {
                            videos.map((x, i) => (
                                <div key={'videos' + i} className="col-12 col-lg-6">
                                    <div className="vid-project">
                                        <b>Project: </b>{videos[i].project}<br /><br />
                                        <b>Video: </b>{
                                            Object.keys(videos[i].vid).map((z, j) => (
                                                <a href={videos[i].vid[z]} style={{ paddingLeft: '10px' }} key={'vid' + i + j}>{z}</a>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio