import React from 'react'

function VerticalAlignment() {
    return (
        <div className='blog-text'>
            <p style={{textAlign:'right', color: 'orange', textShadow:'1px 1px 1px black'}}>Last Updated: 20th March, 2023</p>
            <p>
                Many time we need text to be vertically aligned and we think about the css property vertical-align.
                However, this property don't work this way, it has different meaning than aligning text
                inside an element. Then, how can we middle align text inside an element say div?</p>
            <p>
                <u>Padding-Top or Top</u>: Simplest but not the best way is to use css property 'padding-top' or 'top'
                to set vertical position of text anywhere inside parent element.</p>
            <p>
                <u>Top & Line-Height</u>: You can use top & line-height properties together to positioned
                content vertically having more control on exact position.</p>
            <p>
                Suppose you want to bottom align text inside a div element of height:200px; following css code
                can be used:</p>
            <pre><code>
                {`
            line-height:20px;
            top:180px;
            `}
            </code></pre>
            <p>
                <u>Line-Height</u>: If you need to middle align one-line text vertically, you can set
                line-height property equal to height of container to align text in middle of container
                vertically as given in following example:
            </p>
            <pre><code>
                {`
            border:1px solid gray;
            height:80px;
            line-height:80px;
            Middle Aligned
            `}
            </code></pre>
            <p>
                <u>Position & Transform</u>: Another trick to vertically position the text is by using 'top'
                property and 'translateY' together. Also Text container should be placed inside another
                container set us 'position:relative' whereas text container should be set as 'position:absolute'
                as given in the following example:</p>
            <pre><code>
                {`
            .myContainer {
                border: 1px double gray;
                width:50%;
                height:100px;
                position:relative;
            }

            .myParagraph {
                top: 50%;
                transform: translateY(-50%);
                position:absolute;
            }
            `}
            </code></pre>
            <p>
                It will produce the following result:<br />
            </p>
            <div className="myContainer">
                <div className="myParagraph">Middle Aligned</div>
            </div><br/>
            <p>
                Use following classes to vertical align text in bootstrap 3, 4 and 5:
                d-flex align-items-center or align-self-center</p>
            <p>
                NOTE: 'valign' tag is depricated and should not be used anymore</p>
        </div>
    )
}

export default VerticalAlignment