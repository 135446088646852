import atoe1 from '../gallery/projects/amazontoerp/1.webp';
import atoe2 from '../gallery/projects/amazontoerp/2.webp';
import atoe3 from '../gallery/projects/amazontoerp/3.webp';

import aup1 from '../gallery/projects/aupairs/1.webp';
import aup2 from '../gallery/projects/aupairs/2.webp';
import aup3 from '../gallery/projects/aupairs/3.webp';
import aup4 from '../gallery/projects/aupairs/4.webp';
import aup5 from '../gallery/projects/aupairs/5.webp';
import aup6 from '../gallery/projects/aupairs/6.webp';
import aup7 from '../gallery/projects/aupairs/7.webp';

import azan1 from '../gallery/projects/AzanClock/1.webp';
import azan2 from '../gallery/projects/AzanClock/2.webp';
import azan3 from '../gallery/projects/AzanClock/3.webp';
import azan4 from '../gallery/projects/AzanClock/4.webp';
import azan5 from '../gallery/projects/AzanClock/5.webp';
import azan6 from '../gallery/projects/AzanClock/6.webp';
import azan7 from '../gallery/projects/AzanClock/7.webp';

import bs1 from '../gallery/projects/bluesprings/1.webp';
import bs2 from '../gallery/projects/bluesprings/2.webp';
import bs3 from '../gallery/projects/bluesprings/3.webp';
import bs4 from '../gallery/projects/bluesprings/4.webp';
import bs5 from '../gallery/projects/bluesprings/5.webp';

import dhn1 from '../gallery/projects/dhn/1.webp';
import dhn2 from '../gallery/projects/dhn/2.webp';
import dhn3 from '../gallery/projects/dhn/3.webp';
import dhn4 from '../gallery/projects/dhn/4.webp';
import dhn5 from '../gallery/projects/dhn/5.webp';

import famee1 from '../gallery/projects/famee/1.webp';
import famee2 from '../gallery/projects/famee/2.webp';
import famee3 from '../gallery/projects/famee/3.webp';
import famee4 from '../gallery/projects/famee/4.webp';
import famee5 from '../gallery/projects/famee/5.webp';

import fs1 from '../gallery/projects/FinancialServices/1.webp';
import fs2 from '../gallery/projects/FinancialServices/2.webp';
import fs3 from '../gallery/projects/FinancialServices/3.webp';
import fs4 from '../gallery/projects/FinancialServices/4.webp';

import gmis1 from '../gallery/projects/gmis/1.webp';
import gmis2 from '../gallery/projects/gmis/2.webp';
import gmis3 from '../gallery/projects/gmis/3.webp';
import gmis4 from '../gallery/projects/gmis/4.webp';
import gmis5 from '../gallery/projects/gmis/5.webp';
import gmis6 from '../gallery/projects/gmis/6.webp';
import gmis7 from '../gallery/projects/gmis/7.webp';

import hi1 from '../gallery/projects/homeinspection/1.webp';
import hi2 from '../gallery/projects/homeinspection/2.webp';
import hi3 from '../gallery/projects/homeinspection/3.webp';

import il1 from '../gallery/projects/iLike/1.webp';
import il2 from '../gallery/projects/iLike/2.webp';
import il3 from '../gallery/projects/iLike/3.webp';

import iuspa1 from '../gallery/projects/iuspa/1.webp';
import iuspa2 from '../gallery/projects/iuspa/2.webp';
import iuspa3 from '../gallery/projects/iuspa/3.webp';
import iuspa4 from '../gallery/projects/iuspa/4.webp';
import iuspa5 from '../gallery/projects/iuspa/5.webp';

import lk1 from '../gallery/projects/lubokharta/1.webp';
import lk2 from '../gallery/projects/lubokharta/2.webp';
import lk3 from '../gallery/projects/lubokharta/3.webp';
import lk4 from '../gallery/projects/lubokharta/4.webp';
import lk5 from '../gallery/projects/lubokharta/5.webp';

import plc1 from '../gallery/projects/palcarro/1.webp';
import plc2 from '../gallery/projects/palcarro/2.webp';
import plc3 from '../gallery/projects/palcarro/3.webp';
import plc4 from '../gallery/projects/palcarro/4.webp';
import plc5 from '../gallery/projects/palcarro/5.webp';
import plc6 from '../gallery/projects/palcarro/6.webp';

import pnapp1 from '../gallery/projects/partytonightApp/1.webp';
import pnapp2 from '../gallery/projects/partytonightApp/2.webp';
import pnapp3 from '../gallery/projects/partytonightApp/3.webp';
import pnapp4 from '../gallery/projects/partytonightApp/4.webp';
import pnapp5 from '../gallery/projects/partytonightApp/5.webp';
import pnapp6 from '../gallery/projects/partytonightApp/6.webp';

import pnweb1 from '../gallery/projects/partytonighWeb/1.webp';
import pnweb2 from '../gallery/projects/partytonighWeb/2.webp';
import pnweb3 from '../gallery/projects/partytonighWeb/3.webp';

import pn1 from '../gallery/projects/postnatal/1.webp';
import pn2 from '../gallery/projects/postnatal/2.webp';
import pn3 from '../gallery/projects/postnatal/3.webp';
import pn4 from '../gallery/projects/postnatal/4.webp';
import pn5 from '../gallery/projects/postnatal/5.webp';
import pn6 from '../gallery/projects/postnatal/6.webp';
import pn7 from '../gallery/projects/postnatal/7.webp';

import rles1 from '../gallery/projects/RealEstate/1.webp';
import rles2 from '../gallery/projects/RealEstate/2.webp';
import rles3 from '../gallery/projects/RealEstate/3.webp';
import rles4 from '../gallery/projects/RealEstate/4.webp';
import rles5 from '../gallery/projects/RealEstate/5.webp';
import rles6 from '../gallery/projects/RealEstate/6.webp';
import rles7 from '../gallery/projects/RealEstate/7.webp';

import self1 from '../gallery/projects/Self/1.webp';
import self2 from '../gallery/projects/Self/2.webp';
import self3 from '../gallery/projects/Self/3.webp';
import self4 from '../gallery/projects/Self/4.webp';
import self5 from '../gallery/projects/Self/5.webp';

import sod1 from '../gallery/projects/serviceondemand/1.webp';
import sod2 from '../gallery/projects/serviceondemand/2.webp';
import sod3 from '../gallery/projects/serviceondemand/3.webp';
import sod4 from '../gallery/projects/serviceondemand/4.webp';
import sod5 from '../gallery/projects/serviceondemand/5.webp';

import spz1 from '../gallery/projects/shopzetu/1.webp';
import spz2 from '../gallery/projects/shopzetu/2.webp';
import spz3 from '../gallery/projects/shopzetu/3.webp';
import spz4 from '../gallery/projects/shopzetu/4.webp';
import spz5 from '../gallery/projects/shopzetu/5.webp';
import spz6 from '../gallery/projects/shopzetu/6.webp';
import spz7 from '../gallery/projects/shopzetu/7.webp';

import sh1 from '../gallery/projects/stitchershut/1.webp';
import sh2 from '../gallery/projects/stitchershut/2.webp';
import sh3 from '../gallery/projects/stitchershut/3.webp';
import sh4 from '../gallery/projects/stitchershut/4.webp';
import sh5 from '../gallery/projects/stitchershut/5.webp';

import tb1 from '../gallery/projects/talkingbible/1.webp';
import tb2 from '../gallery/projects/talkingbible/2.webp';
import tb3 from '../gallery/projects/talkingbible/3.webp';
import tb4 from '../gallery/projects/talkingbible/4.webp';
import tb5 from '../gallery/projects/talkingbible/5.webp';

import tfh1 from '../gallery/projects/tofindhouse/1.webp';
import tfh2 from '../gallery/projects/tofindhouse/2.webp';
import tfh3 from '../gallery/projects/tofindhouse/3.webp';
import tfh4 from '../gallery/projects/tofindhouse/4.webp';
import tfh5 from '../gallery/projects/tofindhouse/5.webp';

import trq1 from '../gallery/projects/trueque/1.webp';
import trq2 from '../gallery/projects/trueque/2.webp';
import trq3 from '../gallery/projects/trueque/3.webp';
import trq4 from '../gallery/projects/trueque/4.webp';
import trq5 from '../gallery/projects/trueque/5.webp';

import tbzn1 from '../gallery/projects/tubuzon/1.webp';
import tbzn2 from '../gallery/projects/tubuzon/2.webp';
import tbzn3 from '../gallery/projects/tubuzon/3.webp';
import tbzn4 from '../gallery/projects/tubuzon/4.webp';
import tbzn5 from '../gallery/projects/tubuzon/5.webp';

import wtm1 from '../gallery/projects/wetakeitmoving/1.webp';
import wtm2 from '../gallery/projects/wetakeitmoving/2.webp';
import wtm3 from '../gallery/projects/wetakeitmoving/3.webp';
import wtm4 from '../gallery/projects/wetakeitmoving/4.webp';
import wtm5 from '../gallery/projects/wetakeitmoving/5.webp';
import wtm6 from '../gallery/projects/wetakeitmoving/6.webp';
import wtm7 from '../gallery/projects/wetakeitmoving/7.webp';
import wtm8 from '../gallery/projects/wetakeitmoving/8.webp';
import wtm9 from '../gallery/projects/wetakeitmoving/9.webp';

import erp1 from '../gallery/projects/erp/1.webp';
import erp2 from '../gallery/projects/erp/2.webp';
import erp3 from '../gallery/projects/erp/3.webp';
import erp4 from '../gallery/projects/erp/4.webp';
import erp5 from '../gallery/projects/erp/5.webp';
import erp6 from '../gallery/projects/erp/6.webp';
import erp7 from '../gallery/projects/erp/7.webp';

import ftg1 from '../gallery/projects/foodtruckgo/1.webp';
import ftg2 from '../gallery/projects/foodtruckgo/2.webp';
import ftg3 from '../gallery/projects/foodtruckgo/3.webp';
import ftg4 from '../gallery/projects/foodtruckgo/4.webp';
import ftg5 from '../gallery/projects/foodtruckgo/5.webp';
import ftg6 from '../gallery/projects/foodtruckgo/6.webp';
import ftg7 from '../gallery/projects/foodtruckgo/7.webp';
import ftg8 from '../gallery/projects/foodtruckgo/8.webp';
import ftg9 from '../gallery/projects/foodtruckgo/9.webp';


import adobe from '../gallery/tech/adobesuite/adobe.webp';
import bootstrap1 from '../gallery/tech/bootstrap/1.webp';
import bootstrap2 from '../gallery/tech/bootstrap/2.webp';
import bootstrap3 from '../gallery/tech/bootstrap/3.webp';
import figjam1 from '../gallery/tech/figjam/er1.webp';
import figjam2 from '../gallery/tech/figjam/er2.webp';
import figjam3 from '../gallery/tech/figjam/er3.webp';
import figjam4 from '../gallery/tech/figjam/ctx.webp';
import figjam5 from '../gallery/tech/figjam/dfd.webp';

import trello from '../gallery/tech/jira-trello/trello.webp';
import office from '../gallery/tech/office/office.webp';
import pbi1 from '../gallery/tech/powerbi/pbi1.webp';
import pbi2 from '../gallery/tech/powerbi/pbi2.webp';
import pbi3 from '../gallery/tech/powerbi/pbi3.webp';
import pbi4 from '../gallery/tech/powerbi/pbi4.webp';
import pbi5 from '../gallery/tech/powerbi/pbi5.webp';
import st from '../gallery/tech/softwaretesting/fabcalc.webp';

export const imgsATOE  = [atoe1, atoe2, atoe3];
export const imgsAUP  = [aup1, aup2, aup3, aup4, aup5, aup6, aup7];
export const imgsAZAN  = [azan1, azan2, azan3, azan4, azan5, azan6, azan7];
export const imgsBS = [bs1, bs2, bs3, bs4, bs5];
export const imgsDHN  = [dhn1, dhn2, dhn3, dhn4, dhn5];
export const imgsFAMEE  = [famee1, famee2, famee3, famee4, famee5];
export const imgsFS  = [fs1, fs2, fs3, fs4];
export const imgsGMIS = [gmis1, gmis2, gmis3, gmis4, gmis5, gmis6, gmis7];
export const imgsHI  = [hi1, hi2, hi3];
export const imgsIL  = [il1, il2, il3];
export const imgsIUSPA  = [iuspa1, iuspa2, iuspa3, iuspa4, iuspa5];
export const imgsLK  = [lk1, lk2, lk3, lk4, lk5];
export const imgsPLC  = [plc1, plc2, plc3, plc4, plc5, plc6];
export const imgsPNAPP  = [pnapp1, pnapp2, pnapp3, pnapp4, pnapp5, pnapp6];
export const imgsPNWEB  = [pnweb1, pnweb2, pnweb3];
export const imgsPN  = [pn1, pn2, pn3, pn4, pn5, pn6, pn7];
export const imgsRLES  = [rles1, rles2, rles3, rles4, rles5, rles6, rles7];
export const imgsSELF  = [self1, self2, self3, self4, self5];
export const imgsSOD  = [sod1, sod2, sod3, sod4, sod5];
export const imgsSPZ  = [spz1, spz2, spz3, spz4, spz5, spz6, spz7];
export const imgsSH  = [sh1, sh2, sh3, sh4, sh5];
export const imgsTB  = [tb1, tb2, tb3, tb4, tb5];
export const imgsTFH  = [tfh1, tfh2, tfh3, tfh4, tfh5];
export const imgsTRQ  = [trq1, trq2, trq3, trq4, trq5];
export const imgsTBZN  = [tbzn1, tbzn2, tbzn3, tbzn4, tbzn5];
export const imgsWTM  = [wtm1, wtm2, wtm3, wtm4, wtm5, wtm6, wtm7, wtm8, wtm9];

export const imgsFigma = [bs2, bs2, gmis1, gmis2, bs3];
export const imgsFigJam = [figjam1, figjam2, figjam3, figjam4, figjam5];
export const imgsBootstrap = [bootstrap1, bootstrap2, bootstrap3];
export const imgsAngular = [bs1, bs2, sh1, sh2, iuspa1];
export const imgsReactJS = [gmis1, gmis2, gmis3, tb1, tb2];
export const imgsPowerBI = [pbi1, pbi2, pbi3, pbi4, pbi5];
export const imgsChartJS = [gmis3, gmis4, gmis5];
export const imgsdotNet = [gmis1, gmis2, atoe1];
export const imgsLaravel = [fs1, plc1, tbzn1, wtm1, tfh1];
export const imgsMSSQLServer = [gmis1, gmis2, atoe1];
export const imgsMySQL = [fs2, plc2, tbzn2, wtm2, tfh2];
export const imgsJira = [trello];
export const imgsSoftwareTesting = [st];
export const imgsReactNative = [famee1, famee2, pnapp1, pnapp2, pnapp3];
export const imgsMSOffice = [office];
export const imgsAdobeSuite = [adobe];

export const imgHighlights = [fs1, pnweb1, tfh1, gmis1, aup1];

export const imgsERP = [erp1, erp2, erp3, erp4, erp5, erp6, erp7];
export const imgsFoodTruckGo = [ftg1, ftg2, ftg3, ftg4, ftg5, ftg6, ftg7, ftg8, ftg9];