export const videos = [
    
    {
        id: 14,
        project: 'Food Truck Go!',
        vid: {
            a: 'https://www.loom.com/share/17adf05548e74646bd9ed6baf5750b9b',
            b: 'https://www.loom.com/share/7eb40d6b0a5d421398e9cb6dc8741052',
        },
    },    
    {
        id: 13,
        project: 'ERP',
        vid: {
            a: 'https://www.loom.com/share/9bfe3d76cf5745c5b3a8f58ce6db4344',
        },
    },
    {
        id: 12,
        project: 'Financial Services',
        vid: {
            a: 'https://www.loom.com/share/0209bea182014fceb9cffd41885cede4',
            b: 'https://www.loom.com/share/49302a80e4164fd3812eb2cc1ee409a1',
            c: 'https://www.loom.com/share/22ec826f51c84e4fa13ae9b10831f34c',
            d: 'https://www.loom.com/share/aa99d01ffbfa4e2ba873313fcbc4f2c8',
        },
    },
    {
        id: 11,
        project: 'Palcarro',
        vid: {
            a: 'https://www.loom.com/share/3facd2abbfde421eb453210e480423eb',
            b: 'https://www.loom.com/share/b300fd3011604d2c8b5153681aafb41e',
            c: 'https://www.loom.com/share/faeb597036bc4a8e88d5005571d91957',
        },
    },
    {
        id: 10,
        project: 'Santa Clara Multi-Vendor',
        vid: {
            a: 'https://www.loom.com/share/3098faebe96545eb8fc326f4c1a7afcd',
            b: 'https://www.loom.com/share/66336504aca94d279cf98d0eef7638fd',
            c: 'https://www.loom.com/share/e3b44bd5d9bc49b18cf260e0e5083b2b',
            d: 'https://www.loom.com/share/162459d7cbb748318894fa0aa575b47f',
            e: 'https://www.loom.com/share/e62a801886724424890f6dcc583dfb73',
        },
    },
    /* {
        id: 9,
        project: 'Home Inspection',
        vid: {
            a: 'https://www.loom.com/share/7c0f343dc3e74d46bba1d236be9e1936',
            b: 'https://www.loom.com/share/6f125b0622e340be8a40b2c990841482',
            c: 'https://www.loom.com/share/16f53d88c9f748cab9047506140309dc',
            d: 'https://www.loom.com/share/0d72c318eea44a07b5beabec4b3eec23',
            e: 'https://www.loom.com/share/52c6a8765f174ca89d86ba51c7c622e1',
            f: 'https://www.loom.com/share/33164525de9c4476808e5159ffe7d454',
            g: 'https://www.loom.com/share/9c4af530c6bf4874a8d622ca47459e97',
        },
    }, */
    {
        id: 8,
        project: 'AboutMyCoach',
        vid: {
            a: 'https://www.loom.com/share/07188821e5a3487eb7ff8bc82ca08060',
            b: 'https://www.loom.com/share/4328e84eb98f4795b8a253d7eb3e7c0f',
            c: 'https://www.loom.com/share/d2e7bcfcc20341c28d294b6ea28920eb',
            d: 'https://www.loom.com/share/bf6086ccd27b4d7e9ded00f9687626e9',
            e: 'https://www.loom.com/share/a94a789131ae449eb1bb84217a9971c1',
            f: 'https://www.loom.com/share/b57e71d493174e5e87c2b62743383f3d',
            g: 'https://www.loom.com/share/14f115ceb882429d8ef443e8a79d58bf',
        },
    },
    {
        id: 7,
        project: 'Reborn Athletics',
        vid: {
            a: 'https://www.loom.com/share/bc5fcda1a7484360a30272e3c4521030',
            b: 'https://www.loom.com/share/b998c253198442ee8d482f0be73bdfbb',
            c: 'https://www.loom.com/share/3c30f222963740f9929bae76d99364ad',
        },
    },
    {
        id: 6,
        project: 'Tubuzon',
        vid: {
            a: 'https://www.loom.com/share/888073faa7e842788a43f8167e23ae29',
            b: 'https://www.loom.com/share/93097cecdd3348458879a2e221e81764',
            c: 'https://www.loom.com/share/d746b12a87134ec185c41325743564bf',
            d: 'https://www.loom.com/share/8be5172a94d64a67bc6cc26d3a1fb275',
        },
    },
    /*{
        id: 4,
        project: 'Data Transfer',
        vid: {
            a: 'https://www.loom.com/share/9d740545839148c5b60e477bf060cbcf',
        },
    },
    {
        id: 3,
        project: 'IUSPA',
        vid: {
            a: 'https://www.loom.com/share/28e3c2ded896497688733a09bcd9366a',
            b: 'https://www.loom.com/share/29ddd7407b384a45bd29129b95646a0f',
            c: 'https://www.loom.com/share/45e8114bb9a343ef80933deaad0e1f7f',
            d: 'https://www.loom.com/share/9941a139405542d6a5660b73bfcc84c0',
        },
    },
    {
        id: 2,
        project: 'ToFindHouse',
        vid: {
            a: 'https://www.loom.com/share/5651fa4032ef4141884868eaf2253134',
            b: 'https://www.loom.com/share/a328ad0889784815981e451a66cfc702',
            c: 'https://www.loom.com/share/9cb442ffafa8447cb9d4286b8633856d',
            d: 'https://www.loom.com/share/79c6b63e8ca748f98287289d832cad5b',
        },
    },
    {
        id: 1,
        project: 'BTC Trading',
        vid: {
            a: 'https://www.loom.com/share/5d04ce87906d44128aa52046ff5f80ed',
        },
    }, */
]