import './Styles.scss';
import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Header from './components/Header';
import Stat from './components/Stat';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';
import Blog from './components/Blog';
import Demo from './components/Demo';
import WhoDesignUIUX from './data/blogs/WhoDesignUIUX';
import VerticalAlignment from './data/blogs/VerticalAlignment';
import MultithreadProgramming from './data/blogs/MultithreadProgramming';
import TextBeautify from './data/blogs/TextBeautify';
import MobileAppDevSteps from './data/blogs/MobileAppDevSteps';

//export const UrlParamContext = React.createContext();
function App() {
  //const [urlParam, setUrlParam] = useState(null);
  return (
    <div>
      <Header />
      <Routes>
        <Route path='/' element={<Stat />} />
        <Route path='portfolio' element={<Portfolio />} />
        <Route path='blog' element={<Blog />}>
        <Route path='' element={<WhoDesignUIUX />}/>
          <Route path='who-should-design-ui-ux' element={<WhoDesignUIUX />}/>
          <Route path='how-to-middle-align-text-vertically' element={<VerticalAlignment />}/>
          <Route path='what-is-multi-thread-programming' element={<MultithreadProgramming />}/>
          <Route path='how-to-beautity-text' element={<TextBeautify />}/>
          <Route path='steps-to-develop-mobile-app' element={<MobileAppDevSteps/>}/>
        </Route>
        <Route path='demo' element={<Demo />} />
      </Routes>
      <Footer />
      {/* <UrlParamContext.Provider value={{ urlParam, setUrlParam }}>
        <Navbar />
      </UrlParamContext.Provider>
      <Routes>
        <Route exact path='/' element={<>
          <Theme bannerUrl={b1} bgTheme="bg-silver border-white box-silver" id="t1" btnid="btn1" bcid="bc1" icid="ic1" renderOverlay={true} bgInfog="bg-white" arrowColor="i-white" infogUrl={i1} theme="demographics" themeId="1" />
          <Theme bannerUrl={b6} bgTheme="bg-white border-silver box-white" id="t2" btnid="btn2" bcid="bc2" icid="ic2" renderOverlay={false} bgInfog="bg-silver" arrowColor="i-gold" infogUrl={i6} theme="governance" themeId="2" />
          <Theme bannerUrl={b2} bgTheme="bg-silver border-white box-silver" id="t3" btnid="btn3" bcid="bc3" icid="ic3" renderOverlay={false} bgInfog="bg-white" arrowColor="i-white" infogUrl={i2} theme="health" themeId="3" />
          <Theme bannerUrl={b3} bgTheme="bg-white border-silver box-white" id="t4" btnid="btn4" bcid="bc4" icid="ic4" renderOverlay={false} bgInfog="bg-silver" arrowColor="i-gold" infogUrl={i3} theme="education" themeId="4" />
          <Theme bannerUrl={b4} bgTheme="bg-silver border-white box-silver" id="t5" btnid="btn5" bcid="bc5" icid="ic5" renderOverlay={false} bgInfog="bg-white" arrowColor="i-white" infogUrl={i4} theme="economic participation" themeId="5" />
          <Theme bannerUrl={b5} bgTheme="bg-white border-silver box-white" id="t6" btnid="btn6" bcid="bc6" icid="ic6" renderOverlay={false} bgInfog="bg-silver" arrowColor="i-gold" infogUrl={i5} theme="justice" themeId="6" />
          <Theme bannerUrl={b7} bgTheme="bg-silver border-white box-silver" id="t7" btnid="btn7" bcid="bc7" icid="ic7" renderOverlay={false} bgInfog="bg-white" arrowColor="i-white" infogUrl={i7} theme="special initiative" themeId="7" />
        </>} />
        <Route path='report/:reportId' element={<Report pindId={urlParam} />} />
        <Route path='about' element={<About />} />
        <Route path='indicators' element={<Indicator />} />
        <Route path='links' element={<Links />} />
        <Route path='contact' element={<Contact />} />
        <Route path='team' element={<Team />} />
        <Route path='login' element={<Login />} />
      </Routes> */}
    </div>
  );
}

export default App;
