export const statInfo = [
    {
        id: 1,
        platform: 'Workana',
        noOfProjects: 105,
        completed: 101,
        pulledOut: 4,
        rating: 4.87,
    },
    {
        id: 2,
        platform: 'Fiverr',
        noOfProjects: 2,
        completed: 2,
        pulledOut: 0,
        rating: 5.00,
    },
    {
        id: 3,
        platform: 'Truelancer',
        noOfProjects: 11,
        completed: 11,
        pulledOut: 0,
        rating: 5.00,
    },
]

export const commentInfo = [
    {
        id: 51,
        platform: 'Workana',
        project: 'ERP Development',
        comment: 'Adeel is very responsive and reply to my feedback promptly. He is very thoughtful on system design and I appreciate the good quality of his work.',
        client: 'Wayman',
        date: 'June, 2023',
        },
    {
        id: 50,
        platform: 'Workana',
        project: 'Social Network (Famee App)',
        comment: 'Adeel es responsable y atento para que los trabajos queden como se esperan.',
        client: 'José Manuel',
        date: 'April, 2023',
        },
    {
        id: 1,
        platform: 'Workana',
        project: 'Amazon To ERP',
        comment: 'Nice to work with him, he proposed many solutions to our requirements, always reachable. Happy to work with him again.',
        client: ' Ilyaz khan Mohammed',
        date: 'November, 2022',
        },
    
    {
         id: 2,
         platform: 'Fiverr',
         project: 'Social Media For Pets Selling',
         comment: 'very talented with experience hope to work again thanks.',
         client: 'fattaheg',
         date: 'February, 2021',
        },
    
    {
        id: 3,
        platform: 'Truelancer',
        project: 'aboutmycoach',
        comment: 'Adeel came through again, even taking care of a few things that came up during the work at no charge. I will keep working with Adeel and his team.',
        client: 'Robert',
        date: 'May, 2021',
        },
    
    {
        id: 4,
        platform: 'Workana',
        project: 'Design website for Proptech project',
        comment: 'Adeel has a very high service level.',
        client: ' Bastian Benteler',
        date: 'November, 2022',
        },
    
    {
         id: 5,
         platform: 'Fiverr',
         project: 'Social Media For Pets Selling',
         comment: 'Very experienced with vast knowledge, helped me a lot in my project, thanks.',
         client: 'fattaheg',
         date: 'January, 2021',
        },
    
    {
        id: 6,
        platform: 'Truelancer',
        project: 'aboutmycoach',
        comment: 'Adeel is very responsive and stands behind his work, even after the work is complete. He works to have a good working relationship with his clients. I have enjoyed working with Adeel.',
        client: 'Robert',
        date: 'April, 2021',
        },
    
    {
        id: 7,
        platform: 'Workana',
        project: 'Design of a software program specifically for the home inspection industry',
        comment: 'Professional, great communication, the work done is exactly what I need.',
        client: ' Dutch Jones',
        date: 'August, 2022',
        },
    
    {
        id: 8,
        platform: 'Truelancer',
        project: 'aboutmycoach',
        comment: 'Adeel has been excellent to work with. Very responsive, great communication, and quality outcome with attention to detail. I will continue to work with him and can recommend him without reservation.',
        client: 'Robert',
        date: 'January, 2021',
        },
    
    {
        id: 9,
        platform: 'Workana',
        project: 'Design a mobile app for finding event place',
        comment: 'Adeel is beyond excellence.',
        client: ' Angel & Evardo',
        date: 'June, 2022',
        },
    
    {
        id: 10,
        platform: 'Truelancer',
        project: 'Social Media For Pets Selling',
        comment: 'Good job as usual.',
        client: 'Ahmed',
        date: 'August, 2020',
        },
    
    {
        id: 11,
        platform: 'Workana',
        project: 'Classified Web app',
        comment: 'Muy buena persona y formal.',
        client: 'noureservices',
        date: 'June, 2022',
        },
    
    {
        id: 12,
        platform: 'Truelancer',
        project: 'Business Management',
        comment: 'The project was finished perfectly as required and handed over within the given deadline. Looking forward to working with you again.',
        client: 'Akul',
        date: 'December, 2018',
        },
    
    {
        id: 13,
        platform: 'Workana',
        project: 'E-Learning Website',
        comment: 'He is a very good designer and programmer, fast and deliverable in a good design. a designer and programmer who is helpful and understanding of your needs.',
        client: ' Tommy Ng',
        date: 'May, 2022',
        },
    
    {
        id: 14,
        platform: 'Truelancer',
        project: 'Blue Springs',
        comment: 'Great job, I am 100% satisfied. He works passionately and focused and was always available, even in the late night. Thank you Adeel, keep it up!.',
        client: 'Coding',
        date: 'November, 2018',
        },
    
    {
        id: 15,
        platform: 'Workana',
        project: 'WeTakeIt',
        comment: 'Really good.Even with big time difference between our locations he was always available to help with quick responses and quick solutions.',
        client: ' Pedro Marques',
        date: 'Feb, 2022',
        },
    
    {
        id: 16,
        platform: 'Truelancer',
        project: 'Technical Writing',
        comment: 'He is a very good technical writer, Good work and completed on time.',
        client: 'Shamily',
        date: 'September, 2018',
        },
    
    {
        id: 17,
        platform: 'Workana',
        project: 'Learning Management System',
        comment: 'Very efficient, fast and experienced.There should be no doubt on project management. Can rely on him.',
        client: 'AnepSaaid',
        date: 'January, 2022',
        },
    
    {
        id: 18,
        platform: 'Truelancer',
        project: 'Technical Writing',
        comment: 'Adeel has done a excellent work . I would surely suggest to anyone with the work he have done in the small time frame given.',
        client: 'Lalitha',
        date: 'September, 2018',
        },
    
    {
        id: 19,
        platform: 'Workana',
        project: 'Strigil & Flask Soap Company web site',
        comment: 'Adeel is a professional and he is friendly and communicative upfront. There are always communication issues when working from a distance but Adeel and his team made the process as painless as possible. I was shocked that he also offered one month of service should I have questions. He is making training videos to assist me in the transition into my new website and has been instrumental in providing me with a functional base from which to operate my new business!.',
        client: 'Keith Taylor',
        date: 'January, 2022',
        },
    
    {
        id: 20,
        platform: 'Workana',
        project: 'Social Network (Famee App)',
        comment: 'Es responsable y existe buena comunicación.',
        client: 'José Manuel',
        date: 'January, 2022',
        },
    
    {
        id: 21,
        platform: 'Workana',
        project: 'Humanitarian aid during crisis',
        comment: 'Great work, communication and response. Highly recommended.',
        client: 'Alaa',
        date: 'December, 2021',
        },
    
    {
        id: 22,
        platform: 'Workana',
        project: 'Website Nonprofit',
        comment: 'Adeel is dedicated and responsible. Communication with him is not only efficient, and fluid but extremely friendly.So far the project has been solved in a very professional manner.',
        client: 'Andrea Dowyer',
        date: 'December, 2021',
        },
    
    {
        id: 23,
        platform: 'Workana',
        project: 'Palcarro',
        comment: 'GOOD.',
        client: 'Luis Tomas Sanchez Montas',
        date: 'November, 2021',
        },
    
    {
        id: 24,
        platform: 'Workana',
        project: 'Social Network (Famee App)',
        comment: 'Es un buen profesional muy dedicado.',
        client: 'José Manuel',
        date: 'November, 2021',
        },
    
    {
        id: 25,
        platform: 'Workana',
        project: 'Wayang Kulit Tourist Attraction Center',
        comment: 'Perfect quality of work and delivered in time.',
        client: 'Abiramy Saraswathy',
        date: 'November, 2021',
        },
    
    {
        id: 26,
        platform: 'Workana',
        project: 'Tremor App',
        comment: 'Excellent.',
        client: 'Daniel',
        date: 'October, 2021',
        },
    
    {
        id: 27,
        platform: 'Workana',
        project: 'Tremor App',
        comment: 'Excellent! Always with the correct solution.',
        client: 'Daniel',
        date: 'September, 2021',
        },
    
    {
        id: 28,
        platform: 'Workana',
        project: 'Tremor App',
        comment: 'Excelent Work and dedication!.',
        client: 'Daniel',
        date: 'August, 2021',
        },
    
    {
        id: 29,
        platform: 'Workana',
        project: 'Analización de los problemas de carga de una web Wordpress',
        comment: 'Good communication and good work.',
        client: ' Pablo Bravo Villanueva ',
        date: 'August, 2021',
        },
    
    {
        id: 30,
        platform: 'Workana',
        project: 'Calcular Frete por Km distância (Sistema active ecommerce)',
        comment: 'I recommend. excellent developer. Very attentive and efficient.',
        client: 'Maria Cristina de Amaral',
        date: 'August, 2021',
        },
    
    {
        id: 31,
        platform: 'Workana',
        project: 'Palcarro',
        comment: 'Muy buena comunicación y el trabajo al dia.',
        client: 'Luis Tomas Sanchez Montas',
        date: 'July, 2021',
        },
    
    {
        id: 32,
        platform: 'Workana',
        project: 'Access data from Wix shop using Wix Api',
        comment: '100% satisfied with his work. He goes the extra mile by providing 1 month of support after finalizing the project.',
        client: 'Jose Cruset ',
        date: 'July, 2021',
        },
    
    {
        id: 33,
        platform: 'Workana',
        project: 'Alterações ou inclusões em sistema com Php Laravel e Java',
        comment: 'excelente profissional, muito humilde.',
        client: 'Cristiano Silva',
        date: 'July, 2021',
        },
    
    {
        id: 34,
        platform: 'Workana',
        project: 'Integrate WebPay and Paypal',
        comment: 'very well person and freelancer.',
        client: 'Marcelo',
        date: 'June, 2021',
        },
    
    {
        id: 35,
        platform: 'Workana',
        project: 'Development page register',
        comment: 'The dream project, after 3 developers, promised me that they could do the work, but in the end, and after a long wait, they failed. ' + 
    'Here I found my brother before he was a developer, who made my dream a reality in less than a month. In fact, it is a huge and complex project with many details, but my brother Adeel, not all of that would stop him, on the contrary he was very responsive and understanding despite some modifications and difficulties That we encountered.' +
    'The beautiful thing is that his work is accompanied by good calls, which God really answered thanks to his craftsmanship and his keenness that the project does not end without any deficiency. ' +
    'Thanks again, and now I can say, thank God, thank you brother Adeel you made my dream come true.',
        client: 'Oussama Mazouz ',
        date: 'May, 2021',
        },
    
    {
        id: 36,
        platform: 'Workana',
        project: 'Wordpress Development',
        comment: 'At the beginning of the project, he was too busy to start the project, but I doubted his ability and stopped the project. I had a conversation and trusted him again to restart the project. He accepted most of my demands and quickly developed and gave feedback. There was a time difference of 3 hours, but we took care of each other and proceeded with the development. Doubts about faith began to disappear and reached 30 percent, 60 percent, 70 percent, and 90 percent. In the first version, getting close to 100 percent is actually difficult. Things that are difficult to develop right now will be free for a month. I appreciate his diligence and quick feedback. It was a good choice and I want to request it again when another project is going on. You must have been stressed to accept me being picky until now, but you did a good job. Thank you.',
        client: ' Keanu lee ',
        date: 'March, 2021',
        },
    
    {
        id: 37,
        platform: 'Workana',
        project: 'Shopify Expert',
        comment: 'Great experience working with Adeel, he has great soft skills as a freelancer and availability.',
        client: 'Jonathan Rojas ',
        date: 'February, 2021',
        },
    
    {
        id: 38,
        platform: 'Workana',
        project: 'Design Automotive service Crm and shop management apps',
        comment: 'He is commendable for also giving good suggestions that can help improve your projects.',
        client: ' Steve Paatan',
        date: 'January, 2021',
        },
    
    {
        id: 39,
        platform: 'Workana',
        project: 'Financial Services',
        comment: 'Adeel es muy responsable y eficiente, lo volvería a contratar.',
        client: 'Ruth Iriarte Murdoch',
        date: 'January, 2021',
        },
    
    {
        id: 40,
        platform: 'Workana',
        project: 'Product Microservice development',
        comment: 'Nice Work, clean code and easy to understand !.',
        client: 'Chee Seng Chong',
        date: 'December, 2020',
        },
    
    {
        id: 41,
        platform: 'Workana',
        project: 'Php report',
        comment: 'On constant communication Was able to address required modification.',
        client: ' David Tio',
        date: 'December, 2020',
        },
    
    {
        id: 42,
        platform: 'Workana',
        project: 'Cameo clone with extra function',
        comment: 'Very professional, quick and precise work. Highly recommended.',
        client: 'Nordy Hidayat ',
        date: 'November, 2020',
        },
    
    {
        id: 43,
        platform: 'Workana',
        project: 'Web System to manage courses offered by an IT school',
        comment: "Adeel is an excellent programmer with great experience. I had to deliver an urgent project the same week that I had health problems in my family, so I turned to Workana. Among the various professionals who made a proposal, I chose Adeel. It couldn't have been a better choice. He delivered everything within a very short time and he also promised to continue supporting me for a month. I am very satisfied and I recommend his work to the entire Workana community.",
        client: 'L.C.',
        date: 'November, 2020',
        },
    
    {
        id: 44,
        platform: 'Workana',
        project: 'Finding an Online Tutor',
        comment: 'Very good person, and knowledgeable in his field learned a lot.',
        client: 'Christian Edison Asajar',
        date: 'October, 2020',
        },
    
    {
        id: 45,
        platform: 'Workana',
        project: 'Random Work (Excel Programming)',
        comment: 'Adeel Rauf: "He is precise, a straight talker and on to the job."',
        client: 'Roy Rugdee',
        date: 'September, 2020',
        },
    
    {
        id: 46,
        platform: 'Workana',
        project: 'Stracktech',
        comment: 'Great job with Adeel.',
        client: 'Aleksan Buyuk Kurt',
        date: 'September, 2020',
        },
    
    {
        id: 47,
        platform: 'Workana',
        project: 'Suite Crm Changes',
        comment: 'He was good and thinker out of the box.',
        client: ' Sofian Ajlikin',
        date: 'September, 2020',
        },
    
    {
        id: 48,
        platform: 'Workana',
        project: 'Private Job Reservation and Submission system',
        comment: 'Adeel is a hardworking person who is flexible and willing to make changes for us.',
        client: 'Atlas Studios',
        date: 'August, 2020',
        },
    
    {
        id: 49,
        platform: 'Workana',
        project: 'Mini Quiz',
        comment: 'Really great experience working with him.do the very good job and will happy to deal with him again.',
        client: ' Syahira Yusnaini ',
        date: 'June, 2020',
        },    
]