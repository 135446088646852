import React, { useEffect } from 'react';
import { displayImg, nextTech, techInterval } from '../SharedCode';

function TechStack(props) {    
    const techInfo = props.tech;
    const imgNo = techInfo.imgs;

    useEffect(() => {
        techInterval(imgNo.length);
        return () => {
            clearInterval(nextTech)
        }
    }, [props.tech]);

    return (
        <div className='text-center position-relative img-overlay'>
            {
                imgNo.map((x, i) => (
                    <img src={x} key={'img-tech-' + i} id={'img-tech-' + i} style={{ display: i == 0 ? 'block' : 'none' }} className="img-fluid fade-slide mx-auto" alt="blue springs" />
                ))
            }
            <div className='project-info text-left pt-3'>
                <div style={{ whiteSpace: "pre-wrap", overflow: 'auto' }}>
                    <div className='project-info-txt'>
                        <p><b>Category: </b>{techInfo.category}</p>
                        <p><b>Experience: </b>{techInfo.experience}</p>
                        <p><b>Details: </b>{techInfo.details}</p>
                    </div>
                </div>
            </div>
            {
                imgNo.map((x, i) => (
                    <div key={'dot-tech-' + i} id={'dot-tech-' + i} onClick={() => { displayImg(i, imgNo.length, 'tech'); clearInterval(nextTech) }} style={{ backgroundColor: i == 0 && '#C25B56', cursor: 'pointer' }} className='img-dot'></div>
                ))
            }
        </div>
    )
}

export default TechStack